const color = {
    primary: '#329BEB',
    text: '#535B60',
    bodyBackground: '#F6F6F6',
    invBackground: '#2E3131',
    secLink: '#A2B4C0',
    background: 'white',
    invTextLight: '#535B60',
    invText: 'white',
    separator: '#e4e4e4',
    blockquote: '#ccc'
};

export default {
    color,
    grid: {
        gutter: 14
    },
    card: {
        bottomBorder: `solid 5px ${color.primary}`,
        boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.1)',
    }
};
